import React, { useEffect, useState, createContext } from 'react';

import { useDefault, useAuth } from 'hooks';

import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import { useLocation } from 'react-router-dom';
import dayjs from 'helpers/dayjs';

const ReferralContext = createContext();

const ReferralProvider = ({ children }) => {
  const { list, remove, handleDModal } = useDefault();
  const { isAuthenticated } = useAuth();

  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);
  const location = useLocation();

  //==============================
  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'nickname',
      Header: 'Nickname'
    },
    {
      accessor: 'createdAt',
      Header: 'Fecha de Registro',
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return dayjs(createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
          'YYYY/MM/DD'
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Teléfono'
    },
    {
      accessor: 'code',
      Header: 'Código'
    },
    {
      accessor: 'referral',
      Header: 'Referidos',
      Cell: rowData => {
        const { referral } = rowData.row.original;
        return (
          <span
            className={`${
              referral > 0 ? 'text-primary fw-bold' : 'text-500 fw-semibold'
            } text-sm`}
          >
            {referral > 0 ? referral : 'sin referidos'}
          </span>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            {/* <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            /> */}
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];
  //==============================

  //* GET ALL REGISTER
  useEffect(() => {
    if (
      location.pathname === '/referral/referral-list' ||
      location.pathname === '/dashboard/referrals'
    ) {
      list('referrals/all').then(res => {
        res && setItems(res);
      });
    }
  }, [location, isAuthenticated]);

  //* DELETE
  const removeOne = () => {
    remove(`referrals/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  return (
    <ReferralContext.Provider
      value={{
        removeOne,
        item,
        items,
        setItem,
        setItems,
        columns,
        caption: 'Referidos',
        Form_: null
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
};

ReferralProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ReferralProvider };
export default ReferralContext;
