import React, { useState, useEffect } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import LeadsWidgets from 'components/dashboards/futura/LeadsWidgets';
import { useDefault } from 'hooks';
import { EModal } from 'components/templates/Modal';
import TableModal from 'components/dashboards/futura/TableModal';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import Greetings from 'components/dashboards/greetings/Greetings';
import { format } from 'date-fns';
import dayjs from 'helpers/dayjs';
import Avatar from 'components/common/Avatar';

const LeadsClient = () => {
  const { list } = useDefault();
  const [cities, setCities] = useState([]);
  const [levels, setLevel] = useState([]);
  const [modalitys, setModalitys] = useState([]);
  const [universities, setUniversity] = useState([]);
  const [programs, setProgram] = useState([]);
  const [courses, setCourse] = useState([]);
  const [itemsCities, setItemsCities] = useState([]);
  const [itemsLevels, setItemsLevel] = useState([]);
  const [itemsModalitys, setItemsModalitys] = useState([]);
  const [itemsUniversities, setItemsUniversity] = useState([]);
  const [itemsPrograms, setItemsProgram] = useState([]);
  const [itemsCourses, setItemsCourses] = useState([]);
  const [title, setTitle] = useState('');
  const [allStudents, setallStudents] = useState(null);
  // const [studentsOnDate, setStudentsOnDate] = useState(null);
  const { emodal, dmodal, handleEModal, handleDModal } = useDefault();

  // const date = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    getUser_cities();
    getUser_level();
    getUser_university();
    getUser_program();
    getUser_courses();
    getUser_modality();
  }, []);

  useEffect(() => {
    if (!isDisabled) {
      setItemsCities(cities?.items);
      setItemsLevel(levels?.items);
      setItemsUniversity(universities?.items);
      setItemsProgram(programs?.items);
      setItemsCourses(courses?.items);
      setItemsModalitys(modalitys?.items);
    } else filtrarporFecha();
  }, [isDisabled]);

  const getUser_cities = () => {
    list('dashboard_university_client/list_user_city').then(data => {
      if (data) {
        setCities(data);
        setItemsCities(data?.items);
      }
    });
  };

  const getUser_level = () => {
    list('dashboard_university_client/list_user_level').then(data => {
      if (data) {
        setLevel(data);
        setItemsLevel(data?.items);
      }
    });
  };
  const getUser_modality = () => {
    list('dashboard_university_client/list_user_modality').then(data => {
      if (data) {
        setModalitys(data);
        setItemsModalitys(data?.items);
      }
    });
  };

  const getUser_university = () => {
    list('dashboard_university_client/list_user_university').then(data => {
      if (data) {
        setUniversity(data);
        setItemsUniversity(data?.items);
      }
    });
  };

  const getUser_program = () => {
    list('dashboard_university_client/list_user_program').then(data => {
      if (data) {
        setProgram(data);
        setItemsProgram(data?.items);
      }
    });
  };

  const getUser_courses = () => {
    list('dashboard_university_client/list_user_course').then(data => {
      if (data) {
        setCourse(data);
        setItemsCourses(data?.items);
      }
    });
  };

  const filtrarEstudentsporFecha = (arreglo, fechastart, fechaend) => {
    return arreglo
      .map(objeto => ({
        ...objeto,
        students: objeto.students.filter(
          student =>
            student.register.split('T')[0] >= fechastart &&
            student.register.split('T')[0] <= fechaend
        )
      }))
      .filter(objeto => objeto.students.length > 0);
  };

  const filtrarporFecha = () => {
    if (allStudents && startDate && endDate) {
      setItemsCities([]);
      setItemsLevel([]);
      setItemsUniversity([]);
      setItemsProgram([]);
      setItemsCourses([]);
      const fechafiltrostart = startDate && format(startDate, 'yyyy-MM-dd');
      const fechafiltroend = endDate && format(endDate, 'yyyy-MM-dd');
      setItemsCities(
        filtrarEstudentsporFecha(
          cities?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsLevel(
        filtrarEstudentsporFecha(
          levels?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsModalitys(
        filtrarEstudentsporFecha(
          modalitys?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsUniversity(
        filtrarEstudentsporFecha(
          universities?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsCourses(
        filtrarEstudentsporFecha(
          courses?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsProgram(
        filtrarEstudentsporFecha(
          programs?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
    }
  };

  useEffect(() => {
    filtrarporFecha();
  }, [endDate]);

  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre y Apellidos',
      Cell: rowData => {
        let name = rowData.row.original.name;
        let lastname = rowData.row.original.lastname;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname}</div>
          </div>
        );
      }
    },
    {
      accessor: 'city',
      Header: 'Ciudad'
    },
    {
      accessor: 'university',
      Header: 'Universidad'
    },
    {
      accessor: 'program',
      Header: 'Programa'
    },
    {
      accessor: 'code',
      Header: 'SNIES'
    },
    {
      accessor: 'level',
      Header: 'Nivel Académico'
    },
    {
      accessor: 'modality',
      Header: 'Modalidad'
    },
    {
      accessor: 'price',
      Header: 'Precio',
      Cell: rowData => {
        let name = rowData.row.original.price;
        let cleanNumber = parseFloat(name.replace(/\./g, ''));
        let formattedName = !isNaN(cleanNumber) ? `$${name}` : name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{formattedName}</div>
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Telefono'
    },
    {
      accessor: 'register',
      Header: 'Fecha Aplicación',
      Cell: rowData => {
        const { register } = rowData.row.original;
        return dayjs(register, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD');
      }
    }
  ];
  const columnsCourse = [
    {
      accessor: 'name',
      Header: 'Nombre y Apellidos',
      Cell: rowData => {
        let name = rowData.row.original.name;
        let lastname = rowData.row.original.lastname;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname}</div>
          </div>
        );
      }
    },
    {
      accessor: 'course',
      Header: 'Cliente',
      Cell: rowData => {
        let name =
          rowData.row.original.courseUniversity !== null
            ? rowData.row.original.courseUniversity
            : rowData.row.original.courseMark;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'courseName',
      Header: 'Curso'
    },
    {
      accessor: 'price',
      Header: 'Precio',
      Cell: rowData => {
        let name = rowData.row.original.coursePrice;
        let cleanNumber = parseFloat(name.replace(/\./g, ''));
        let formattedName = !isNaN(cleanNumber) ? `$${name}` : name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{formattedName}</div>
          </div>
        );
      }
    },
    {
      accessor: 'courseLevel',
      Header: 'Nivel'
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Telefono'
    },

    {
      accessor: 'register',
      Header: 'Fecha Aplicación',
      Cell: rowData => {
        const { register } = rowData.row.original;
        return dayjs(register, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD');
      }
    }
  ];

  return (
    <>
      <Greetings
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
      />
      {/* {'total: ' + studentsOnDate} */}
      <Row className="g-3 mb-3">
        <Alert.Heading>Usuarios interesados en los Programas</Alert.Heading>
        <Col md={4} xxl={4}>
          <LeadsWidgets
            // data={cities?.cities}
            items={itemsCities}
            title="Ciudad"
            setTitle={setTitle}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            background={bg1}
          />
        </Col>
        <Col md={4} xxl={4}>
          <LeadsWidgets
            // data={universities?.universities}
            items={itemsUniversities}
            title="Universidad"
            setTitle={setTitle}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            background={bg1}
          />
        </Col>

        <Col md={4} xxl={4}>
          <LeadsWidgets
            items={itemsPrograms}
            title="Programas"
            setTitle={setTitle}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            background={bg2}
          />
        </Col>
        <Col md={6} xxl={6}>
          <LeadsWidgets
            items={itemsLevels}
            title="Nivel"
            setTitle={setTitle}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            background={bg3}
          />
        </Col>
        <Col md={6} xxl={6}>
          <LeadsWidgets
            items={itemsModalitys}
            title="Modalidad"
            setTitle={setTitle}
            allStudents={allStudents}
            setallStudents={setallStudents}
            handleEModal={handleEModal}
            background={bg3}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={6}>
          <Alert.Heading>Usuarios interesados en los Cursos</Alert.Heading>
          <Col md={12} xxl={12}>
            <LeadsWidgets
              // data={programs?.programs}
              items={itemsCourses}
              title="Cursos"
              setTitle={setTitle}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleDModal}
              background={bg2}
            />
          </Col>
        </Col>
      </Row>

      <EModal
        form_={
          <TableModal
            items={allStudents}
            title={`Listado de Estudiantes por ${title}`}
            columns={columns}
          />
        }
        title={''}
        modalSize={'xl'}
        modal={emodal}
        handleModal={handleEModal}
      />
      <EModal
        form_={
          <TableModal
            items={allStudents}
            title={`Listado de Estudiantes por ${title}`}
            columns={columnsCourse}
          />
        }
        title={''}
        modalSize={'xl'}
        modal={dmodal}
        handleModal={handleDModal}
      />
    </>
  );
};

export default LeadsClient;
