import React, { useEffect, useState, createContext } from 'react';

import { useDefault, useAuth } from 'hooks';

import Buttons from 'components/templates/Buttons';
import Form_ from 'components/forms/OrientationForm';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import { useLocation } from 'react-router-dom';

const OrientationContext = createContext();

const OrientationProvider = ({ children }) => {
  const { list, update, add, remove, handleEModal, handleDModal } =
    useDefault();

  const { isAuthenticated } = useAuth();

  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);
  const location = useLocation();
  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'price',
      Header: 'Precio',
      Cell: rowData => {
        const { price } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">$ {price}</div>
          </div>
        );
      }
    },
    {
      accessor: 'financing',
      Header: 'Tipo Test'
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  //* FUNTION FOR SAVE OR MODIFY REGISTER
  const submit = object_ => {
    if (object_.get('_id')) {
      object_._id = object_.get('_id');
      update('orientation/update', object_).then(res => {
        res &&
          (setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          ),
          setItem({}));
      });
    } else
      add('orientation/add', object_).then(
        res => res && setItems([...items, res])
      );
  };

  //* GET ALL REGISTER
  useEffect(() => {
    if (location.pathname === '/cms/orientation') {
      list('orientation/all').then(res => res && setItems(res));
    }
  }, [location, isAuthenticated]);

  //* DELETE
  const removeOne = () => {
    remove(`orientation/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  return (
    <OrientationContext.Provider
      value={{
        item,
        items,
        setItem,
        setItems,
        columns,
        Form_,
        submit,
        removeOne,
        confirm: false,
        caption: 'Orientación y Ayuda',
        captions: 'Orientaciones y Ayudas'
      }}
    >
      {children}
    </OrientationContext.Provider>
  );
};

OrientationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { OrientationProvider };
export default OrientationContext;
