import React from 'react';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import GreetingsDate from './GreetingsDate';
import PropTypes from 'prop-types';

const Greetings = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isDisabled,
  setIsDisabled
}) => {
  const handleDate = () => {
    setIsDisabled(isDisabled ? false : true);
  };
  return (
    <Card className="bg-100 shadow-none border mb-3">
      <Card.Body className="py-0">
        <Row className="g-0 justify-content-between">
          <Col sm="auto">
            <Flex alignItems="center">
              <img src={barChart} width={90} alt="..." className="ms-n4" />
              <div>
                <h6 className="text-primary fs-10 mb-0">Bienvenido </h6>
                <h4 className="text-primary fw-bold mb-0">
                  Futura
                  <span className="text-info fw-medium"> Leads</span>
                </h4>
              </div>
              <img
                src={lineChart}
                width={140}
                alt="..."
                className="ms-n4 d-md-none d-lg-block"
              />
            </Flex>
          </Col>
          <Col md="auto" className="mb-3 mb-md-0">
            <Row className="g-3 gy-md-0 h-100 align-items-center">
              <Col xs="auto">
                <h6 className="text-700 mb-0 text-nowrap">
                  Mostrar datos por fecha:
                </h6>
              </Col>
              <Col>
                <input
                  type="checkbox"
                  checked={isDisabled}
                  onChange={handleDate}
                />
              </Col>
              <Col md="auto">
                {isDisabled && (
                  <GreetingsDate
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Greetings.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func,
  isDisabled: PropTypes.bool,
  setIsDisabled: PropTypes.func
};

export default Greetings;
