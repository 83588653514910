import React, { useState, useEffect } from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import LeadsWidgets from 'components/dashboards/futura/LeadsWidgets';
import { useDefault } from 'hooks';
import { EModal } from 'components/templates/Modal';
import TableModal from 'components/dashboards/futura/TableModal';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import Greetings from 'components/dashboards/greetings/Greetings';
import { format } from 'date-fns';
import dayjs from 'helpers/dayjs';
import Datatable from 'components/templates/datatable/Datatable';
import Avatar from 'components/common/Avatar';
import Buttons from 'components/templates/Buttons';
import Flex from 'components/common/Flex';
import CustomerInfo from 'components/dashboards/e-commerce/customers-details/CustomerInfo';

const Leads = () => {
  const { list } = useDefault();
  const [cities, setCities] = useState([]);
  const [levels, setLevel] = useState([]);
  const [modalitys, setModalitys] = useState([]);
  const [universities, setUniversity] = useState([]);
  const [programs, setProgram] = useState([]);
  const [courses, setCourse] = useState([]);
  const [orientations, setOrientations] = useState([]);
  const [banks, setBanks] = useState([]);
  const [itemsCities, setItemsCities] = useState([]);
  const [itemsLevels, setItemsLevel] = useState([]);
  const [itemsModalitys, setItemsModalitys] = useState([]);
  const [itemsUniversities, setItemsUniversity] = useState([]);
  const [itemsPrograms, setItemsProgram] = useState([]);
  const [itemsCourses, setItemsCourses] = useState([]);
  const [itemsOrientations, setItemsOrientations] = useState([]);
  const [itemsBanks, setItemsBanks] = useState([]);
  const [title, setTitle] = useState('');
  const [allStudents, setallStudents] = useState(null);
  const [users, setUsers] = useState(null);
  const [detail, setDetail] = useState(null);
  const {
    emodal,
    cmodal,
    dmodal,
    fmodal,
    bmodal,
    handleEModal,
    handleFModal,
    handleCModal,
    handleDModal,
    handleBModal
  } = useDefault();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    getUser_cities();
    getUser_level();
    getUser_university();
    getUser_program();
    getUser_courses();
    getUsers();
    getUser_orientations();
    getUser_modality();
    getUser_bank();
  }, []);

  useEffect(() => {
    if (!isDisabled) {
      setItemsCities(cities?.items);
      setItemsLevel(levels?.items);
      setItemsUniversity(universities?.items);
      setItemsProgram(programs?.items);
      setItemsCourses(courses?.items);
      setItemsOrientations(orientations?.items);
      setItemsModalitys(modalitys?.items);
      setItemsBanks(banks?.items);
    } else {
      filtrarporFecha();
    }
  }, [isDisabled]);

  const getUser_cities = () => {
    list('dashboard_futura/list_user_city').then(data => {
      if (data) {
        setCities(data);
        setItemsCities(data?.items);
      }
    });
  };
  const getUser_orientations = () => {
    list('dashboard_futura/list_user_orientation').then(data => {
      if (data) {
        setOrientations(data);
        setItemsOrientations(data?.items);
      }
    });
  };

  const getUser_level = () => {
    list('dashboard_futura/list_user_level').then(data => {
      if (data) {
        setLevel(data);
        setItemsLevel(data?.items);
      }
    });
  };
  const getUser_modality = () => {
    list('dashboard_futura/list_user_modality').then(data => {
      if (data) {
        setModalitys(data);
        setItemsModalitys(data?.items);
      }
    });
  };

  const getUser_university = () => {
    list('dashboard_futura/list_user_university').then(data => {
      if (data) {
        setUniversity(data);
        setItemsUniversity(data?.items);
      }
    });
  };

  const getUser_program = () => {
    list('dashboard_futura/list_user_program').then(data => {
      if (data) {
        setProgram(data);
        setItemsProgram(data?.items);
      }
    });
  };

  const getUser_courses = () => {
    list('dashboard_futura/list_user_course').then(data => {
      if (data) {
        setCourse(data);
        setItemsCourses(data?.items);
      }
    });
  };
  const getUser_bank = () => {
    list('dashboard_futura/list_user_bank').then(data => {
      if (data) {
        setBanks(data);
        setItemsBanks(data?.items);
      }
    });
  };

  const getUsers = () => {
    list('dashboard_futura/list').then(data => {
      if (data) {
        setUsers(data);
      }
    });
  };

  const filtrarEstudentsporFecha = (arreglo, fechastart, fechaend) => {
    return arreglo
      .map(objeto => ({
        ...objeto,
        students: objeto.students.filter(
          student =>
            student.register.split('T')[0] >= fechastart &&
            student.register.split('T')[0] <= fechaend
        )
      }))
      .filter(objeto => objeto.students.length > 0);
  };

  const filtrarporFecha = () => {
    if (allStudents && startDate && endDate) {
      setItemsCities([]);
      setItemsLevel([]);
      setItemsUniversity([]);
      setItemsProgram([]);
      setItemsCourses([]);
      const fechafiltrostart = startDate && format(startDate, 'yyyy-MM-dd');
      const fechafiltroend = endDate && format(endDate, 'yyyy-MM-dd');
      setItemsCities(
        filtrarEstudentsporFecha(
          cities?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsLevel(
        filtrarEstudentsporFecha(
          levels?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsModalitys(
        filtrarEstudentsporFecha(
          modalitys?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsUniversity(
        filtrarEstudentsporFecha(
          universities?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsCourses(
        filtrarEstudentsporFecha(
          courses?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsProgram(
        filtrarEstudentsporFecha(
          programs?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsOrientations(
        filtrarEstudentsporFecha(
          orientations?.items,
          fechafiltrostart,
          fechafiltroend
        )
      );
      setItemsBanks(
        filtrarEstudentsporFecha(banks?.items, fechafiltrostart, fechafiltroend)
      );
    }
  };

  useEffect(() => {
    filtrarporFecha();
  }, [endDate]);

  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre y Apellidos',
      Cell: rowData => {
        let name = rowData.row.original.name;
        let lastname = rowData.row.original.lastname;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname}</div>
          </div>
        );
      }
    },
    {
      accessor: 'city',
      Header: 'Ciudad'
    },
    {
      accessor: 'university',
      Header: 'Universidad'
    },
    {
      accessor: 'program',
      Header: 'Programa'
    },
    {
      accessor: 'code',
      Header: 'SNIES'
    },
    {
      accessor: 'level',
      Header: 'Nivel Académico'
    },
    {
      accessor: 'modality',
      Header: 'Modalidad'
    },
    {
      accessor: 'price',
      Header: 'Precio',
      Cell: rowData => {
        let name = rowData.row.original.price;
        let cleanNumber = parseFloat(name.replace(/\./g, ''));
        let formattedName = !isNaN(cleanNumber) ? `$${name}` : name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{formattedName}</div>
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Telefono'
    },
    {
      accessor: 'register',
      Header: 'Fecha Aplicación',
      Cell: rowData => {
        const { register } = rowData.row.original;
        return dayjs(register, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD');
      }
    }
  ];
  const columnsCourse = [
    {
      accessor: 'name',
      Header: 'Nombre y Apellidos',
      Cell: rowData => {
        let name = rowData.row.original.name;
        let lastname = rowData.row.original.lastname;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname}</div>
          </div>
        );
      }
    },
    {
      accessor: 'course',
      Header: 'Cliente',
      Cell: rowData => {
        let name =
          rowData.row.original.courseUniversity !== null
            ? rowData.row.original.courseUniversity
            : rowData.row.original.courseMark;
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'courseName',
      Header: 'Curso'
    },
    {
      accessor: 'price',
      Header: 'Precio',
      Cell: rowData => {
        let name = rowData.row.original.coursePrice;
        let cleanNumber = parseFloat(name.replace(/\./g, ''));
        let formattedName = !isNaN(cleanNumber) ? `$${name}` : name;

        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{formattedName}</div>
          </div>
        );
      }
    },
    {
      accessor: 'courseLevel',
      Header: 'Nivel'
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Telefono'
    },

    {
      accessor: 'register',
      Header: 'Fecha Aplicación',
      Cell: rowData => {
        const { register } = rowData.row.original;
        return dayjs(register, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD');
      }
    }
  ];
  const columnsOrientation = [
    {
      accessor: 'name',
      Header: 'Nombre y Apellidos',
      Cell: rowData => {
        let name = rowData.row.original.name;
        let lastname = rowData.row.original.lastname;
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => {
              setDetail(rowData.row.original);
              handleCModal();
            }}
          >
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname}</div>
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Telefono'
    },
    {
      accessor: 'orientationName',
      Header: 'Orientación'
    },
    {
      accessor: 'register',
      Header: 'Fecha Aplicación',
      Cell: rowData => {
        const { register } = rowData.row.original;
        return dayjs(register, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD');
      }
    }
  ];
  const columnsBank = [
    {
      accessor: 'name',
      Header: 'Nombre y Apellidos',
      Cell: rowData => {
        let name = rowData.row.original.name;
        let lastname = rowData.row.original.lastname;
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => {
              setDetail(rowData.row.original);
              handleCModal();
            }}
          >
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname}</div>
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Correo'
    },
    {
      accessor: 'phone',
      Header: 'Telefono'
    },
    {
      accessor: 'bankName',
      Header: 'Banco'
    },
    {
      accessor: 'register',
      Header: 'Fecha Aplicación',
      Cell: rowData => {
        const { register } = rowData.row.original;
        return dayjs(register, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY/MM/DD');
      }
    }
  ];

  const columnsUser = [
    {
      accessor: 'name',
      Header: 'Nombre y Apellidos',
      Cell: rowData => {
        let name = 'Desconocido';
        let lastname = 'Desconocido';

        if (rowData.row.original.user) {
          name = rowData.row.original.user.name;
          lastname = rowData.row.original.user.lastname;
        }
        return (
          <div
            className="d-flex align-items-center"
            onClick={() => {
              setDetail(rowData.row.original);
              handleCModal();
            }}
          >
            <Avatar size="l" name={name} />
            <div className="ms-2">{name + ' ' + lastname}</div>
          </div>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Correo',
      Cell: rowData => {
        let email = 'Desconocido';

        if (rowData.row.original.user) {
          email = rowData.row.original.user.email;
        }
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{email}</div>
          </div>
        );
      }
    },
    {
      accessor: 'phone',
      Header: 'Teléfono',
      Cell: rowData => {
        let phone = 'Desconocido';

        if (rowData.row.original.user) {
          phone = rowData.row.original.user.phone;
        }
        return (
          <div className="d-flex align-items-center">
            <div className="ms-2">{phone}</div>
          </div>
        );
      }
    },
    {
      accessor: 'register',
      Header: 'Fecha de Registro',
      Cell: rowData => {
        return dayjs(
          rowData.row.original.user.createdAt,
          'YYYY-MM-DDTHH:mm:ss.SSSZ'
        ).format('YYYY/MM/DD');
      }
    },
    {
      accessor: 'btn',
      Header: 'Detalles',
      Cell: rowData => {
        // const { _id, status } = rowData.row.original;
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'options'}
              handleEvent={() => {
                setDetail(rowData.row.original);
                handleCModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  if (users !== null)
    return (
      <>
        <Greetings
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
        />
        {/* {'total: ' + studentsOnDate} */}
        <Row className="g-3 mb-3">
          <Alert.Heading>Usuarios interesados en los Programas</Alert.Heading>
          <Col md={4} xxl={4}>
            <LeadsWidgets
              // data={cities?.cities}
              items={itemsCities}
              title="Ciudad"
              setTitle={setTitle}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              background={bg1}
            />
          </Col>
          <Col md={4} xxl={4}>
            <LeadsWidgets
              // data={universities?.universities}
              items={itemsUniversities}
              title="Universidad"
              setTitle={setTitle}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              background={bg1}
            />
          </Col>

          <Col md={4} xxl={4}>
            <LeadsWidgets
              items={itemsPrograms}
              title="Programas"
              setTitle={setTitle}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              background={bg2}
            />
          </Col>
          <Col md={6} xxl={6}>
            <LeadsWidgets
              items={itemsLevels}
              title="Nivel"
              setTitle={setTitle}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              background={bg3}
            />
          </Col>
          <Col md={6} xxl={6}>
            <LeadsWidgets
              items={itemsModalitys}
              title="Modalidad"
              setTitle={setTitle}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              background={bg3}
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col md={6} xxl={6}>
            <Alert.Heading>Usuarios interesados en los Cursos</Alert.Heading>
            <Col md={12} xxl={12}>
              <LeadsWidgets
                // data={programs?.programs}
                items={itemsCourses}
                title="Cursos"
                setTitle={setTitle}
                allStudents={allStudents}
                setallStudents={setallStudents}
                handleEModal={handleDModal}
                background={bg2}
              />
            </Col>
          </Col>
          <Col md={6} xxl={6}>
            <Alert.Heading>
              Usuarios interesados en las Orientaciones
            </Alert.Heading>
            <Col md={12} xxl={12}>
              <LeadsWidgets
                // data={programs?.programs}
                items={itemsOrientations}
                title="Orientaciones"
                setTitle={setTitle}
                allStudents={allStudents}
                setallStudents={setallStudents}
                handleEModal={handleFModal}
                background={bg2}
              />
            </Col>
          </Col>
          <Col md={6} xxl={6}>
            <Alert.Heading>Usuarios interesados en los Bancos</Alert.Heading>
            <Col md={12} xxl={12}>
              <LeadsWidgets
                // data={programs?.programs}
                items={itemsBanks}
                title="Bancos"
                setTitle={setTitle}
                allStudents={allStudents}
                setallStudents={setallStudents}
                handleEModal={handleBModal}
                background={bg2}
              />
            </Col>
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col md={12} xxl={12}>
            <Datatable
              title={`Listado de Usuarios`}
              data={users}
              columns={columnsUser}
            />
          </Col>
        </Row>

        <EModal
          form_={
            <TableModal
              items={allStudents}
              title={`Listado de Estudiantes por ${title}`}
              columns={columns}
            />
          }
          title={''}
          modalSize={'xl'}
          modal={emodal}
          handleModal={handleEModal}
        />
        <EModal
          form_={
            <TableModal
              items={allStudents}
              title={`Listado de Estudiantes por ${title}`}
              columns={columnsCourse}
            />
          }
          title={''}
          modalSize={'xl'}
          modal={dmodal}
          handleModal={handleDModal}
        />
        <EModal
          form_={
            <TableModal
              items={allStudents}
              title={`Listado de Estudiantes por ${title}`}
              columns={columnsOrientation}
            />
          }
          title={''}
          modalSize={'xl'}
          modal={fmodal}
          handleModal={handleFModal}
        />
        <EModal
          form_={
            <TableModal
              items={allStudents}
              title={`Listado de Estudiantes por ${title}`}
              columns={columnsBank}
            />
          }
          title={''}
          modalSize={'xl'}
          modal={bmodal}
          handleModal={handleBModal}
        />
        <EModal
          form_={<CustomerInfo items={detail} />}
          title={''}
          modalSize={'xl'}
          modal={cmodal}
          handleModal={handleCModal}
        />
      </>
    );
};

export default Leads;
