import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDefault, useAuth, http } from 'hooks';
import Form_ from 'components/forms/CoursesForm';
import { toast } from 'react-toastify';
import headers from 'config/header';

import Buttons from 'components/templates/Buttons';
import { SwitchDt } from 'components/SwitchDt';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';
import { useLocation, useNavigate } from 'react-router-dom';
import CardDropdown from 'components/common/CardDropdown';
import { Dropdown } from 'react-bootstrap';

const CourseContext = createContext();

const CourseProvider = ({ children }) => {
  const {
    list,
    remove,
    add,
    status,
    update,
    handleCModal,
    handleDModal,
    handleEModal,
    handleFModal
  } = useDefault();
  const { isAuthenticated, auth } = useAuth();
  const university = auth?.user_university
    ? auth.user_university
    : auth.university_profile || {};
  const mark = auth?.user_mark ? auth.user_mark : auth.mark || {};
  const navigate = useNavigate();
  const modalSize = 'xl';
  const [name, setName] = useState(null);
  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);
  const location = useLocation();

  const handleClick = row => {
    setItem(row);
    setName(row.name);
    navigate('/cms/scholarship-course', { replace: true });
  };
  const handleClickRole = row => {
    setItem(row);
    setName(row.name);
    navigate('/cms/role-course', { replace: true });
  };
  //==============================
  let columns = [];
  if (Object.keys(university).length !== 0 || Object.keys(mark).length !== 0) {
    columns = [
      {
        accessor: 'name',
        Header: 'Nombre',
        Cell: rowData => {
          const { name } = rowData.row.original;
          return (
            <div className="ms-2 text-truncate" style={{ maxWidth: '300px' }}>
              {name}
            </div>
          );
        }
      },
      {
        accessor: 'scholarship',
        Header: 'Beca Futura',
        Cell: rowData => {
          const { _id, scholarship } = rowData.row.original;
          return (
            <div className="d-flex align-items-center">
              <SwitchDt
                id={_id}
                status={scholarship}
                pt={false}
                tx={'Deshacer beca futura'}
                tx0={'Hacer beca futura'}
                handledEvent={() => {
                  setItem(rowData.row.original);
                  handleFModal();
                }}
              />
            </div>
          );
        }
      },
      {
        accessor: 'agreement',
        Header: 'Estado',
        Cell: rowData => {
          const { agreement } = rowData.row.original;
          return agreement ? (
            <SoftBadge pill bg="success">
              Convenio
              <FontAwesomeIcon icon="check" className="ms-2" />{' '}
            </SoftBadge>
          ) : (
            <SoftBadge pill bg="secondary" className="me-2">
              No convenio
              <FontAwesomeIcon icon="ban" className="ms-2" />
            </SoftBadge>
          );
        }
      },
      {
        accessor: 'bf',
        Header: '',
        Cell: rowData => {
          const { scholarship } = rowData.row.original;
          return (
            <>
              <CardDropdown>
                <div className="py-2">
                  {scholarship && (
                    <Dropdown.Item
                      onClick={() => handleClick(rowData.row.original)}
                    >
                      Becas
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    onClick={() => handleClickRole(rowData.row.original)}
                  >
                    Roles
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </>
          );
        }
      },
      {
        accessor: 'btn',
        Header: 'Acciones',
        Cell: rowData => {
          const { _id, agreement } = rowData.row.original;
          return (
            <Flex className="gap-1 mt-1.5">
              <SwitchDt
                id={_id}
                status={agreement}
                pt={false}
                tx={'Deshacer convenio'}
                tx0={'Hacer convenio'}
                handledEvent={() => {
                  setItem(rowData.row.original);
                  handleCModal();
                }}
              />
              <Buttons
                btn={'update'}
                handleEvent={() => {
                  setItem(rowData.row.original);
                  handleEModal();
                }}
              />
              <Buttons
                btn={'delete'}
                handleEvent={() => {
                  setItem(rowData.row.original);
                  handleDModal();
                }}
              />
            </Flex>
          );
        }
      }
    ];
  } else {
    columns = [
      {
        accessor: 'university',
        Header: 'Cliente',
        Cell: rowData => {
          const { mark, university } = rowData.row.original;
          let item;
          if (mark) {
            item = mark;
          } else {
            item = university;
          }
          return (
            <div className="d-flex align-items-center">
              <Avatar size="l" name={item?.name} />
              <div className="ms-2">{item?.name}</div>
            </div>
          );
        }
      },
      {
        accessor: 'name',
        Header: 'Nombre',
        Cell: rowData => {
          const { name } = rowData.row.original;
          return (
            <div className="ms-2 text-truncate" style={{ maxWidth: '300px' }}>
              {name}
            </div>
          );
        }
      },
      {
        accessor: 'scholarship',
        Header: 'Beca Futura',
        Cell: rowData => {
          const { _id, scholarship } = rowData.row.original;
          return (
            <div className="d-flex align-items-center">
              <SwitchDt
                id={_id}
                status={scholarship}
                pt={false}
                tx={'Deshacer beca futura'}
                tx0={'Hacer beca futura'}
                handledEvent={() => {
                  setItem(rowData.row.original);
                  handleFModal();
                }}
              />
            </div>
          );
        }
      },
      {
        accessor: 'agreement',
        Header: 'Estado',
        Cell: rowData => {
          const { agreement } = rowData.row.original;
          return agreement ? (
            <SoftBadge pill bg="success">
              Convenio
              <FontAwesomeIcon icon="check" className="ms-2" />{' '}
            </SoftBadge>
          ) : (
            <SoftBadge pill bg="secondary" className="me-2">
              No convenio
              <FontAwesomeIcon icon="ban" className="ms-2" />
            </SoftBadge>
          );
        }
      },
      {
        accessor: 'bf',
        Header: '',
        Cell: rowData => {
          const { scholarship } = rowData.row.original;
          return (
            <>
              <CardDropdown>
                <div className="py-2">
                  {scholarship && (
                    <Dropdown.Item
                      onClick={() => handleClick(rowData.row.original)}
                    >
                      Becas
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    onClick={() => handleClickRole(rowData.row.original)}
                  >
                    Roles
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </>
          );
        }
      },
      {
        accessor: 'btn',
        Header: 'Acciones',
        Cell: rowData => {
          const { _id, agreement } = rowData.row.original;
          return (
            <Flex className="gap-1 mt-1.5">
              <SwitchDt
                id={_id}
                status={agreement}
                pt={false}
                tx={'Deshacer convenio'}
                tx0={'Hacer convenio'}
                handledEvent={() => {
                  setItem(rowData.row.original);
                  handleCModal();
                }}
              />
              <Buttons
                btn={'update'}
                handleEvent={() => {
                  setItem(rowData.row.original);
                  handleEModal();
                }}
              />
              <Buttons
                btn={'delete'}
                handleEvent={() => {
                  setItem(rowData.row.original);
                  handleDModal();
                }}
              />
            </Flex>
          );
        }
      }
    ];
  }

  //==============================

  const changeStatus = async () => {
    status(`courses/update`, {
      _id: item._id,
      agreement: !item.agreement
    }).then(res => {
      res &&
        setItems(
          items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
        );
      setItem({});
    });
  };
  const changeFutura = async () => {
    try {
      let config = headers();

      if (config.headers.token.length > 50) {
        const { data } = await http.put(
          '/courses/update',
          { _id: item._id, scholarship: !item.scholarship },
          config
        );

        //! Async width .State.
        const updateObj = items.map(stateObj =>
          stateObj._id === data.data._id ? data.data : stateObj
        );
        setItems(updateObj);
        toast.success(data.message);
      } else toast.error('Invalid token!');
    } catch (e) {
      console.log(e);
    } finally {
      handleFModal();
      setItem({});
    }
  };

  //* GET ALL REGISTER
  useEffect(() => {
    if (location.pathname === '/cms/courses') {
      list('courses/list').then(data => data && setItems(data));
    }
  }, [location, isAuthenticated]);

  const removeOne = async () => {
    remove(`courses/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  //* FUNTION FOR SAVE OR MODIFY REGISTER
  const submit = object_ => {
    if (object_.id) {
      object_._id = object_.id;
      update('courses/update', object_).then(res => {
        res &&
          (setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          ),
          setItem({}));
      });
    } else
      add('courses/add', object_).then(res => res && setItems([...items, res]));
  };

  return (
    <CourseContext.Provider
      value={{
        changeStatus,
        changeFutura,
        removeOne,
        item,
        items,
        setItem,
        setItems,
        submit,
        columns,
        name,
        caption: 'Curso/Bootcamp',
        captions: 'Cursos/Bootcamps',
        Form_,
        confirm: true,
        modalSize
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

CourseProvider.propTypes = {
  children: PropTypes.node
};

export { CourseProvider };
export default CourseContext;
