import React, { useEffect, useState, createContext } from 'react';

import { useDefault, useAuth } from 'hooks';

import Buttons from 'components/templates/Buttons';
import Form_ from 'components/forms/LevelsEducationForm';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import dayjs from 'helpers/dayjs';

const LevelsEducationContext = createContext();

const LevelsEducationProvider = ({ children }) => {
  const { list, update, add, remove, handleEModal, handleDModal } =
    useDefault();
  const { isAuthenticated } = useAuth();

  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);

  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'createdAt',
      Header: 'Creado',
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return dayjs(createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
          'YYYY/MM/DD'
        );
      }
    },
    {
      accessor: 'updatedAt',
      Header: 'Actualizado',
      Cell: rowData => {
        const { updatedAt } = rowData.row.original;
        return dayjs(updatedAt).fromNow();
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      headerProps: { style: { width: '95px' } },
      Cell: rowData => {
        return (
          <Flex className="gap-1 mt-1.5">
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];

  //* FUNTION FOR SAVE OR MODIFY REGISTER
  const submit = object_ => {
    if (object_.id) {
      object_._id = object_.id;
      update('levels-education/update', object_).then(res => {
        res &&
          (setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          ),
          setItem({}));
      });
    } else
      add('levels-education/add', object_).then(
        res => res && setItems([...items, res])
      );
  };

  //* GET ALL REGISTER
  useEffect(() => {
    list('levels-education/all').then(res => res && setItems(res));
  }, [isAuthenticated]);

  //* DELETE
  const removeOne = () => {
    remove(`levels-education/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  return (
    <LevelsEducationContext.Provider
      value={{
        item,
        items,
        setItem,
        setItems,
        columns,
        Form_,
        submit,
        removeOne,
        confirm: false,
        caption: 'Nivel de Formación',
        captions: 'Niveles de Formación'
      }}
    >
      {children}
    </LevelsEducationContext.Provider>
  );
};

LevelsEducationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { LevelsEducationProvider };
export default LevelsEducationContext;
