import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
  Tab,
  Tabs
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  useBasicCores,
  useMarksCourses,
  useLevels,
  useModalitys,
  useDefault,
  useAuth,
  // useKnowledgeArea,
  // useLevelsEducation,
  useUniversity
} from 'hooks';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import AppContext from 'context/Context';
import { Editor } from '@tinymce/tinymce-react';
import { getColor } from 'helpers/utils';
import { toast } from 'react-toastify';

const CoursesForm = ({ submit, item, items, modal }) => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);

  const { auth } = useAuth();
  // const role = auth?.user_role ? auth.user_role : auth.role || {};
  const university = auth?.user_university
    ? auth.user_university
    : auth.university_profile || {};
  const mark = auth?.user_mark ? auth.user_mark : auth.mark || {};
  const { loadBotton } = useDefault();
  const [availabledMsg, setAvailabledMsg] = useState('');
  const [availabled, setAvailabled] = useState(true);
  const [availabledMsgSelect, setAvailabledMsgSelect] = useState('');
  const [availabledSelect, setAvailabledSelect] = useState(true);
  const [availabledMsgLevel, setAvailabledMsgLevel] = useState('');
  const [availabledLevel, setAvailabledLevel] = useState(true);
  const [availabledMsgMark, setAvailabledMsgMark] = useState('');
  const [availabledMark, setAvailabledMark] = useState(true);
  const [availabledMsgModality, setAvailabledMsgModality] = useState('');
  const [availabledModality, setAvailabledModality] = useState(true);
  // const [availabledMsgKnowledgeArea, setAvailabledMsgKnowledgeArea] =
  //   useState('');
  // const [availabledKnowledgeArea, setAvailabledKnowledgeArea] = useState(true);
  // const [availabledMsgLevelsEducation, setAvailabledMsgLevelsEducation] =
  //   useState('');
  // const [availabledLevelsEducation, setAvailabledLevelsEducation] =
  //   useState(true);
  const [option, setOption] = useState(null);
  const [optionLevel, setOptionLevel] = useState(null);
  const [optionMark, setOptionMark] = useState(null);
  const [optionModality, setOptionModality] = useState(null);
  // const [optionKnowledgeArea, setOptionKnowledgeArea] = useState(null);
  // const [optionLevelsEducation, setOptionLevelsEducation] = useState(null);
  const { items: basicCores } = useBasicCores();
  const { items: markCourses } = useMarksCourses();
  const { items: levels } = useLevels();
  const { items: modalitys } = useModalitys();
  // const { items: knowledgeAreas } = useKnowledgeArea();
  // const { items: levelsEducation } = useLevelsEducation();
  const { items: universities } = useUniversity();
  const [availabledMsgUniversity, setAvailabledMsgUniversity] = useState('');
  const [availabledUniversity, setAvailabledUniversity] = useState(true);
  const [optionUniversity, setOptionUniversity] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    // reset,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues: { id: null } });

  let pt = false;
  useEffect(() => {
    if (modal && !pt) {
      pt = true;
      toast.warning(
        'La opcíón de Guardar no estará habilitada hasta tanto los campos señalados como obligatorio (*) estén llenos.'
      );
    }
  }, [modal]);

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    setAvailabledSelect(true);
    setAvailabledMsgSelect('');
    if (
      option !== null &&
      optionLevel !== null &&
      optionModality !== null
      // optionKnowledgeArea !== null &&
      // optionLevelsEducation !== null
    ) {
      data.basic_core = option.value;
      data.level = optionLevel.value;
      if (Object.keys(mark).length !== 0) {
        data.mark = mark;
      } else if (Object.keys(university).length !== 0) {
        data.university = university;
      } else {
        if (optionUniversity) {
          data.university = optionUniversity.value;
        } else {
          data.mark = optionMark.value;
        }
      }
      data.modality = optionModality.value;
      // data.knowledge_area = optionKnowledgeArea.value;
      // data.education_level = optionLevelsEducation.value;
      submit(data);
      // setOption(null);
      // setOptionLevel(null);
      // setOptionMark(null);
      // setOptionModality(null);
      // setOptionKnowledgeArea(null);
      // setOptionLevelsEducation(null);
      // reset();
    } else {
      if (option === null) {
        setAvailabledSelect(false);
        setAvailabledMsgSelect(
          'debe seleccionar un Núcleo Básico del Conocimiento !'
        );
      }
      if (optionLevel === null) {
        setAvailabledLevel(false);
        setAvailabledMsgLevel('debe seleccionar un Nivel Académico !');
      }
      if (optionMark === null) {
        setAvailabledMark(false);
        setAvailabledMsgMark('debe seleccionar un Cliente!');
      }
      if (optionModality === null) {
        setAvailabledModality(false);
        setAvailabledMsgModality('debe seleccionar una Modalidad!');
      }
      // if (optionKnowledgeArea === null) {
      //   setAvailabledKnowledgeArea(false);
      //   setAvailabledMsgKnowledgeArea(
      //     'debe seleccionar un Área del Conocimiento!'
      //   );
      // }
      // if (optionLevelsEducation === null) {
      //   setAvailabledLevelsEducation(false);
      //   setAvailabledMsgLevelsEducation(
      //     'debe seleccionar un Nivel de Formación!'
      //   );
      // }
      if (optionUniversity === null) {
        setAvailabledUniversity(false);
        setAvailabledMsgUniversity('debe seleccionar una Unievrsidad!');
      }
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    if (item?._id) {
      setValue('id', item._id);
      setValue('name', item.name);
      setValue('about', item.about);
      setValue('final_registration', item.final_registration?.split('T')[0]);
      setValue('module', item.module);
      setValue('duration', item.duration);
      setValue('title_grants', item.title_grants);
      setValue('start_classes', item.start_classes?.split('T')[0]);
      setValue('price', item.price);
      setValue('name_client', item.name_client);
      setValue('video_about', item.video_about);
      setValue('learn_about', item.learn_about);
      setValue('url_landing_page', item.url_landing_page);

      // setValue('modality', item.modality);
      const selectedOption = basicCores.find(
        find => find._id === item.basic_core?._id
      );
      if (selectedOption) {
        setOption({ value: selectedOption._id, label: selectedOption.name });
      }
      // const selectedLevelsEducation = levelsEducation.find(
      //   find => find._id === item.education_level?._id
      // );
      // if (selectedLevelsEducation) {
      //   setOptionLevelsEducation({
      //     value: selectedLevelsEducation._id,
      //     label: selectedLevelsEducation.name
      //   });
      // }
      // const selectedKnowledgeArea = knowledgeAreas.find(
      //   find => find._id === item.knowledge_area?._id
      // );
      // if (selectedKnowledgeArea) {
      //   setOptionKnowledgeArea({
      //     value: selectedKnowledgeArea._id,
      //     label: selectedKnowledgeArea.name
      //   });
      // }
      const selectedMark = markCourses.find(
        find => find._id === item.mark?._id
      );
      if (selectedMark) {
        setOptionMark({
          value: selectedMark._id,
          label: selectedMark.name
        });
      }
      const selectedLevel = levels.find(find => find._id === item.level?._id);
      if (selectedLevel) {
        setOptionLevel({
          value: selectedLevel._id,
          label: selectedLevel.name
        });
      }
      const selectedModality = modalitys.find(
        find => find._id === item.modality?._id
      );
      if (selectedModality) {
        setOptionModality({
          value: selectedModality._id,
          label: selectedModality.name
        });
      }
      const selectedUniversity = universities.find(
        find => find._id === item.university?._id
      );
      if (selectedUniversity) {
        setOptionUniversity({
          value: selectedUniversity._id,
          label: selectedUniversity.name
        });
      }
    }
  }, [watch('id')]);

  useEffect(() => {
    if (!item?._id || item?.name !== watch('name')) {
      setAvailabled(true);
      setAvailabledMsg('');
      items.forEach(dat => {
        if (dat.name === watch('name')) {
          setAvailabled(false);
          setAvailabledMsg('este nombre está en uso actualmente!');
        }
      });
    }
  }, [watch('name')]);

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
      <Row>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
          <Tab eventKey="home" title="Información general">
            <Row>
              <Col>
                <div className="row w-100">
                  {Object.keys(university).length === 0 &&
                    Object.keys(mark).length === 0 && (
                      <div id="mark-row">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1 mt-2">
                            Marca (*)
                          </Form.Label>
                          <ReactSelect
                            closeMenuOnSelect={true}
                            options={markCourses.map(find => ({
                              value: find._id,
                              label: find.name
                            }))}
                            placeholder="Seleccionar...."
                            classNamePrefix="react-select"
                            value={optionMark}
                            id="mark"
                            onChange={optionMark => setOptionMark(optionMark)}
                          />
                          {availabledMark ? null : (
                            <h6 className="text-danger fs--2 mt-2 mb-0">
                              {availabledMsgMark}
                            </h6>
                          )}
                        </Form.Group>
                      </div>
                    )}
                  <div id="name-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">Nombre (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Nombre del E-Learning"
                        isInvalid={!!errors.name}
                        {...register('name', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name && errors.name.message}
                      </Form.Control.Feedback>
                      {availabled ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsg}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="title_grants-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Certificado (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="title_grants"
                        name="title_grants"
                        placeholder="Certificado a obtener"
                        isInvalid={!!errors.title_grants}
                        {...register('title_grants', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title_grants && errors.title_grants.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="row w-100">
                  {Object.keys(university).length === 0 &&
                    Object.keys(mark).length === 0 && (
                      <div id="university-row">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1 mt-2">
                            Universidad (*)
                          </Form.Label>
                          <ReactSelect
                            closeMenuOnSelect={true}
                            options={universities.map(find => ({
                              value: find._id,
                              label: find.name
                            }))}
                            placeholder="Seleccionar...."
                            classNamePrefix="react-select"
                            value={optionUniversity}
                            id="university"
                            onChange={option => setOptionUniversity(option)}
                          />
                          {availabledUniversity ? null : (
                            <h6 className="text-danger fs--2 mt-2 mb-0">
                              {availabledMsgUniversity}
                            </h6>
                          )}
                        </Form.Group>
                      </div>
                    )}
                  <div id="final_registration-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">
                        Fecha final Inscripciones (*)
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="date"
                        id="final_registration"
                        name="final_registration"
                        placeholder="Fecha final Inscripciones"
                        isInvalid={!!errors.final_registration}
                        max={watch('start_classes')}
                        {...register('final_registration', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.final_registration &&
                          errors.final_registration.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div id="start_classes-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">
                        Inicio de clases (*)
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="date"
                        min={watch('final_registration')}
                        id="start_classes"
                        name="start_classes"
                        placeholder="Inicio de clases"
                        isInvalid={!!errors.start_classes}
                        {...register('start_classes', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.start_classes && errors.start_classes.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div id="url_landing_page-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">
                        URL Landing page (*)
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="url_landing_page"
                        name="url_landing_page"
                        placeholder="URL Landing page"
                        isInvalid={!!errors.url_landing_page}
                        {...register('url_landing_page', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.url_landing_page &&
                          errors.url_landing_page.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </Col>
              <div id="about-row" className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-1">
                    Sobre el E-Learning (*)
                  </Form.Label>
                  <div style={{ border: errors.about ? '2px solid red' : '' }}>
                    <Editor
                      id="about"
                      name="about"
                      value={watch('about')}
                      onEditorChange={newValue => {
                        setValue('about', newValue);
                        if (errors.about) clearErrors('about');
                      }}
                      apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                      init={{
                        height: '200px',
                        menubar: false,
                        content_style: `
                        .mce-content-body {
                          color: ${
                            isDark ? 'white' : getColor('emphasis-color')
                          };                          
                          background-color: ${getColor(
                            'tinymce-bg'
                          )};                          
                        }`,
                        statusbar: false,
                        plugins: 'lists directionality',
                        toolbar:
                          'styleselect | bold italic link bullist numlist image table media undo redo',
                        directionality: isRTL ? 'rtl' : 'ltr',
                        theme_advanced_toolbar_align: 'center'
                      }}
                      {...register('about', {
                        required: {
                          value: true,
                          message: 'este campo es requerido!'
                        }
                      })}
                    />
                  </div>
                  {errors.about && (
                    <div style={{ color: 'red' }}>{errors.about.message}</div>
                  )}
                </Form.Group>
              </div>
            </Row>
          </Tab>
          <Tab eventKey="details" title="Detalle Curso/Bootcamp">
            <Row>
              <Col>
                <div className="row w-100">
                  <div id="level-row">
                    <Form.Group className="mb-1">
                      <Form.Label className="mb-1 mt-2">
                        Nivel Académico (*)
                      </Form.Label>
                      <ReactSelect
                        closeMenuOnSelect={true}
                        options={levels.map(find => ({
                          value: find._id,
                          label: find.name
                        }))}
                        placeholder="Seleccionar...."
                        classNamePrefix="react-select"
                        value={optionLevel}
                        id="level"
                        onChange={optionLevel => setOptionLevel(optionLevel)}
                      />
                      {availabledLevel ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsgLevel}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="basic_core-row">
                    <Form.Group className="mb-1">
                      <Form.Label className="mb-1 mt-2">
                        Núcleo Básico del Conocimiento (*)
                      </Form.Label>
                      <ReactSelect
                        closeMenuOnSelect={true}
                        options={basicCores.map(find => ({
                          value: find._id,
                          label: find.name
                        }))}
                        placeholder="Seleccionar...."
                        classNamePrefix="react-select"
                        value={option}
                        id="basic_core"
                        onChange={option => setOption(option)}
                      />
                      {availabledSelect ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsgSelect}
                        </h6>
                      )}
                    </Form.Group>
                  </div>

                  <div id="modality-row">
                    <Form.Group className="mb-1">
                      <Form.Label className="mb-1 mt-2">
                        Modalidad (*)
                      </Form.Label>
                      <ReactSelect
                        closeMenuOnSelect={true}
                        options={modalitys.map(find => ({
                          value: find._id,
                          label: find.name
                        }))}
                        placeholder="Seleccionar...."
                        classNamePrefix="react-select"
                        value={optionModality}
                        id="modality"
                        onChange={optionModality =>
                          setOptionModality(optionModality)
                        }
                      />
                      {availabledModality ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsgModality}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </Col>

              <Col>
                <div className="row w-100">
                  <div id="duration-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Duración (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="duration"
                        name="duration"
                        placeholder="Duración"
                        isInvalid={!!errors.duration}
                        {...register('duration', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.duration && errors.duration.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div id="module-row" className="mb-3">
                    <Form.Group>
                      <Form.Label className="mb-1">Módulos (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="module"
                        name="module"
                        rows={3}
                        placeholder="Módulos"
                        isInvalid={!!errors.module}
                        {...register('module', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.module && errors.module.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div id="price-row" className="mb-3">
                    <Form.Label className="mb-1">Precio (*)</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <FormControl
                        size="sm"
                        type="text"
                        id="price"
                        name="price"
                        placeholder="1.000.000"
                        isInvalid={!!errors.price}
                        {...register('price', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.price && errors.price.message}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <div
                      className={`${
                        localStorage.getItem('isDark') === 'true'
                          ? 'text-400'
                          : 'text-700'
                      }`}
                      style={{
                        fontSize: '12px'
                      }}
                    >
                      Solo valor numérico con puntos decimales
                    </div>
                    {/* <Form.Group>
                      <Form.Label className="mb-1">Precio (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="price"
                        name="price"
                        placeholder="Precio"
                        isInvalid={!!errors.price}
                        {...register('price', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.price && errors.price.message}
                      </Form.Control.Feedback>
                    </Form.Group> */}
                  </div>
                </div>
              </Col>
            </Row>
            <div id="learn_about-row" className="mb-3">
              <Form.Group>
                <Form.Label className="mb-1">Qué aprenderás (*)</Form.Label>
                <div
                  style={{ border: errors.learn_about ? '2px solid red' : '' }}
                >
                  <Editor
                    id="learn_about"
                    name="learn_about"
                    value={watch('learn_about')}
                    onEditorChange={newValue => {
                      setValue('learn_about', newValue);
                      if (errors.learn_about) clearErrors('learn_about');
                    }}
                    apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                    init={{
                      height: '200px',
                      menubar: false,
                      content_style: `
                        .mce-content-body {
                          color: ${
                            isDark ? 'white' : getColor('emphasis-color')
                          };                          
                          background-color: ${getColor('tinymce-bg')};
                        }`,
                      statusbar: false,
                      plugins: 'lists directionality',
                      toolbar:
                        'styleselect | bold italic link bullist numlist image table media undo redo',

                      directionality: isRTL ? 'rtl' : 'ltr',
                      theme_advanced_toolbar_align: 'center'
                    }}
                    {...register('learn_about', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      }
                    })}
                  />
                </div>
                {errors.learn_about && (
                  <div style={{ color: 'red' }}>
                    {errors.learn_about.message}
                  </div>
                )}
              </Form.Group>
            </div>
          </Tab>
        </Tabs>
      </Row>

      <Button
        type="submit"
        size="sm"
        variant="primary"
        disabled={
          loadBotton ||
          Object.keys(errors).length > 0 ||
          !availabled ||
          option === null ||
          optionLevel === null ||
          optionModality === null ||
          !watch('name') ||
          !watch('title_grants') ||
          !watch('final_registration') ||
          !watch('start_classes') ||
          !watch('about') ||
          !watch('duration') ||
          !watch('module') ||
          !watch('price') ||
          !watch('learn_about')
        }
        className="rounded-pill me-1 mb-3 w-100 d-inline-flex flex-center gap-1 p-1"
      >
        {loadBotton && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {loadBotton
          ? 'Cargando…'
          : watch('id')
          ? 'Actualizar Datos'
          : 'Guardar Datos'}
      </Button>
    </Form>
  );
};

CoursesForm.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array,
  modal: PropTypes.bool
};

export default CoursesForm;
