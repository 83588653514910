import React, { useEffect, useState, createContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDefault, useAuth, http } from 'hooks';
import Form_ from 'components/forms/UniversityForm';

import Buttons from 'components/templates/Buttons';
import { SwitchDt } from 'components/SwitchDt';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Avatar from 'components/common/Avatar';
import CardDropdown from 'components/common/CardDropdown';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import headers from 'config/header';
const UniversityContext = createContext();

const UniversityProvider = ({ children }) => {
  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);
  const [univConv, setUnivConv] = useState([]);
  const [name, setName] = useState(null);
  const modalSize = 'xl';
  const {
    list,
    remove,
    status,
    handleCModal,
    handleEModal,
    update,
    add,
    handleDModal
  } = useDefault();

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const handleClick = (row, url) => {
    setItem(row);
    setName(row.name);
    navigate(`/cms/university/${url}`, { replace: true });
  };
  const handleClickAgreement = row => {
    let errorMessages = [];

    if (!row.campus || row.campus.length === 0) {
      errorMessages.push('Sede');
    }

    if (!row.agreements || row.agreements.length === 0) {
      errorMessages.push('Convenio');
    }

    if (!row.welfare || row.welfare.length === 0) {
      errorMessages.push('Bienestar');
    }

    if (!row.bonds || row.bonds.length === 0) {
      errorMessages.push('Becas');
    }

    if (!row.rectors || row.rectors.length === 0) {
      errorMessages.push('Rector');
    }

    if (!row.images || row.images.length === 0) {
      errorMessages.push('Imagen');
    }

    if (errorMessages.length > 0) {
      const errorMessage = `Debe ir a opciones y agregar al menos 1 ${errorMessages.join(
        ', '
      )}`;
      toast.error(errorMessage);
    } else {
      setItem(row);
      handleCModal();
    }
  };
  //==============================
  const columns = [
    {
      accessor: 'name',
      Header: 'Nombre',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <Avatar size="l" name={name} />
            <div className="ms-2">{name}</div>
          </div>
        );
      }
    },
    {
      accessor: 'cities.name',
      Header: 'Cuidad'
    },
    {
      accessor: 'agreement',
      Header: 'Estado',
      Cell: rowData => {
        const { agreement } = rowData.row.original;
        return agreement ? (
          <SoftBadge pill bg="success">
            Convenio
            <FontAwesomeIcon icon="check" className="ms-2" />{' '}
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            No convenio
            <FontAwesomeIcon icon="ban" className="ms-2" />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'bf',
      Header: 'Opciones',
      Cell: rowData => {
        return (
          <>
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  onClick={() => handleClick(rowData.row.original, 'campus')}
                >
                  Sedes
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleClick(rowData.row.original, 'agreements')
                  }
                >
                  Convenios
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleClick(rowData.row.original, 'welfare')}
                >
                  Bienestar
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleClick(rowData.row.original, 'bonds')}
                >
                  Becas
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleClick(rowData.row.original, 'rectors')}
                >
                  Directivas
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleClick(rowData.row.original, 'images')}
                >
                  Galería
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleClick(rowData.row.original, 'requirements')
                  }
                >
                  Requisitos
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </>
        );
      }
    },
    {
      accessor: 'btn',
      Header: 'Acciones',
      Cell: rowData => {
        const { _id, agreement } = rowData.row.original;
        return (
          <Flex className="gap-1 mt-1.5">
            {' '}
            <SwitchDt
              id={_id}
              status={agreement}
              pt={false}
              tx={'Deshacer convenio'}
              tx0={'Hacer convenio'}
              handledEvent={() => handleClickAgreement(rowData.row.original)}
            />
            <Buttons
              btn={'update'}
              handleEvent={() => {
                rowData.row.original.pt = false;
                setItem(rowData.row.original);
                handleEModal();
              }}
            />
            <Buttons
              btn={'delete'}
              handleEvent={() => {
                setItem(rowData.row.original);
                handleDModal();
              }}
            />
          </Flex>
        );
      }
    }
  ];
  //==============================

  const changeStatus = async () => {
    status(`universities/update`, {
      _id: item._id,
      agreement: !item.agreement
    }).then(res => {
      res &&
        setItems(
          items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
        );
      setItem({});
    });
  };

  //* GET ALL REGISTER
  useEffect(() => {
    list('universities/list').then(data => data && setItems(data));
  }, [isAuthenticated]);

  //* GET ALL UNIV. CONVENIUN
  useEffect(() => {
    if (isAuthenticated) {
      getUnivConv();
    }
  }, [isAuthenticated]);
  const getUnivConv = async () => {
    try {
      const { data } = await http('universities/agreements', headers());
      setUnivConv(data);
    } catch (e) {
      console.log(e);
    }
  };
  const submit = object_ => {
    if (object_.get('_id')) {
      object_._id = object_.get('_id');
      update('universities/update', object_).then(res => {
        res &&
          (setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          ),
          setItem({}));
      });
    } else
      add('universities/add', object_).then(
        res => res && setItems([...items, res])
      );
  };
  //const submit = async () => {};

  const removeOne = async () => {
    remove(`universities/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  return (
    <UniversityContext.Provider
      value={{
        changeStatus,
        removeOne,
        item,
        items,
        setItem,
        setItems,
        columns,
        name,
        modalSize,
        submit,
        univConv,
        caption: 'Universidad',
        captions: 'Universidades',
        Form_: Form_,
        confirm: true
      }}
    >
      {children}
    </UniversityContext.Provider>
  );
};

UniversityProvider.propTypes = {
  children: PropTypes.node
};

export { UniversityProvider };
export default UniversityContext;
