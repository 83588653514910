import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, Form, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  useCities,
  useLevels,
  useModalitys,
  useBasicCores,
  usePeriods,
  useUniversity,
  useDefault,
  useAuth
} from 'hooks';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import AppContext from 'context/Context';
import { Editor } from '@tinymce/tinymce-react';
import { getColor } from 'helpers/utils';
import { toast } from 'react-toastify';

const University = ({ submit, item, items, modal }) => {
  const {
    config: { isDark, isRTL }
  } = useContext(AppContext);

  const { auth } = useAuth();
  const university = auth?.user_university
    ? auth.user_university
    : auth.university_profile || {};

  const { loadBotton } = useDefault();
  const [availabledCodeMsg, setAvailabledCodeMsg] = useState('');
  const [availabledCode, setAvailabledCode] = useState(true);
  const [availabledMsgCities, setAvailabledMsgCities] = useState('');
  const [availabledCities, setAvailabledCities] = useState(true);
  const [optionCities, setOptionCities] = useState(null);
  const [availabledMsgLevels, setAvailabledMsgLevels] = useState('');
  const [availabledLevels, setAvailabledLevels] = useState(true);
  const [optionLevel, setOptionLevel] = useState(null);
  const [availabledMsgModality, setAvailabledMsgModality] = useState('');
  const [availabledModality, setAvailabledModality] = useState(true);
  const [optionModality, setOptionModality] = useState(null);
  const [availabledMsgBasicCores, setAvailabledMsgBasicCores] = useState('');
  const [availabledBasicCores, setAvailabledBasicCores] = useState(true);
  const [optionBasicCores, setOptionBasicCores] = useState(null);
  const [availabledMsgPeriods, setAvailabledMsgPeriods] = useState('');
  const [availabledPeriods, setAvailabledPeriods] = useState(true);
  const [optionPeriods, setOptionPeriods] = useState(null);
  const [availabledMsgUniversity, setAvailabledMsgUniversity] = useState('');
  const [availabledUniversity, setAvailabledUniversity] = useState(true);
  const [optionUniversity, setOptionUniversity] = useState(null);
  const [availabledPDF, setAvailabledPDF] = useState(null);
  const [pdf, setPDF] = useState(null);
  const [disappear, setDisappear] = useState(false);
  const { items: cities } = useCities();
  const { items: levels } = useLevels();
  const { items: modalitys } = useModalitys();
  const { items: basicCores } = useBasicCores();
  const { items: periods } = usePeriods();
  const { items: universities } = useUniversity();
  const [egresado, setEgresado] = useState();
  const [egresadoUrl, setEgresadoUrl] = useState();
  const [availabledEgresado, setAvailabledEgresado] = useState(null);
  const [charge, setCharge] = useState();
  const [chargeUrl, setChargeUrl] = useState();
  const [availabledCharge, setAvailabledCharge] = useState(null);
  const [scholarship, setScholarship] = useState();
  const [scholarshipUrl, setScholarshipUrl] = useState();
  const [availabledScholarship, setAvailabledScholarship] = useState(null);
  const [spread, setSpread] = useState();
  const [spreadUrl, setSpreadUrl] = useState();
  const [availabledSpread, setAvailabledSpread] = useState(null);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    // reset,
    formState: { errors },
    clearErrors
  } = useForm({ defaultValues: { id: null } });

  let pt = false;
  useEffect(() => {
    if (modal && !pt) {
      pt = true;
      toast.warning(
        'La opcíón de Guardar no estará habilitada hasta tanto los campos señalados como obligatorio (*) estén llenos.'
      );
    }
  }, [modal]);

  //*EVENT CLICK SAVE AND UPDATE
  const onSubmit = data => {
    const formData = new FormData();
    if (item._id) {
      formData.append('_id', item._id);
    }
    if (
      optionCities !== null &&
      optionLevel !== null &&
      optionBasicCores !== null &&
      optionModality !== null &&
      optionPeriods !== null &&
      optionUniversity !== null
    ) {
      formData.append('about', data.about);
      formData.append('name', data.name);
      formData.append('city', optionCities.value);
      formData.append('level', optionLevel.value);
      formData.append('basic_core', optionBasicCores.value);
      formData.append('modality', optionModality.value);
      formData.append('periods', optionPeriods.value);

      // formData.append('university', optionUniversity.value);
      if (Object.keys(university).length !== 0) {
        formData.append('university', university);
      } else {
        formData.append('university', optionUniversity.value);
      }
      formData.append('studyplans', pdf ? pdf : item.studyplans);
      formData.append('final_registration', data.final_registration);
      formData.append('start_classes', data.start_classes);
      formData.append('code', data.code);
      formData.append('workshift', data.workshift);
      formData.append('credits', data.credits);
      formData.append('duration', data.duration);
      formData.append('price', data.price);
      formData.append('title_grants', data.title_grants);
      formData.append('salary', data.salary);
      formData.append('accredited', disappear);
      formData.append('video_about', data.video_about);
      formData.append(
        'requirements',
        data.requirements !== null ? data.requirements : ''
      );
      formData.append(
        'url_facebook',
        data.url_facebook !== null ? data.url_facebook : ''
      );
      formData.append(
        'url_linkedin',
        data.url_linkedin !== null ? data.url_linkedin : ''
      );
      formData.append(
        'url_instagram',
        data.url_instagram !== null ? data.url_instagram : ''
      );
      formData.append('name_egresado', data.name_egresado);
      formData.append(
        'about_egresado',
        data.about_egresado ? data.about_egresado : ''
      );

      formData.append('image_egresado', egresado);
      formData.append('image_cargos', charge);
      formData.append('image_diferenciales', spread);
      formData.append('image_auxilios', scholarship);
      submit(formData);
      // reset();
      saveImageEgresado(
        `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`
      );
      saveImageCharge(
        `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`
      );
      saveImageScholarship(
        `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`
      );
      saveImageSpread(
        `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`
      );
    } else {
      if (optionCities === null) {
        setAvailabledCities(false);
        setAvailabledMsgCities('debe seleccionar una ciudad !');
      }
      if (optionLevel === null) {
        setAvailabledLevels(false);
        setAvailabledMsgLevels('debe seleccionar un Nivel Académico!');
      }
      if (optionBasicCores === null) {
        setAvailabledBasicCores(false);
        setAvailabledMsgBasicCores(
          'debe seleccionar un Nucleo Básico Conocimiento!'
        );
      }
      if (optionModality === null) {
        setAvailabledModality(false);
        setAvailabledMsgModality('debe seleccionar una Modalidad!');
      }
      if (optionPeriods === null) {
        setAvailabledPeriods(false);
        setAvailabledMsgPeriods('debe seleccionar un Períodos de Duración!');
      }
      if (optionUniversity === null) {
        setAvailabledUniversity(false);
        setAvailabledMsgUniversity('debe seleccionar una Unievrsidad!');
      }
    }
  };

  //* EVENT GET ALL RGISTER
  useEffect(() => {
    // let brochureURL;
    let egresadoUrl;
    let chargeURL;
    let spreadUrl;
    let scholarshipURL;
    if (item?._id) {
      setValue('id', item._id);
      setValue('name', item.name);
      setValue('about', item.about);
      if (item.final_registration)
        setValue('final_registration', item.final_registration.split('T')[0]);
      if (item.start_classes)
        setValue('start_classes', item.start_classes.split('T')[0]);
      setValue('code', item.code);
      setValue('credits', item.credits);
      setValue('duration', item.duration);
      setValue('price', item.price);
      setValue('salary', item.salary);
      setValue('accredited', item.accredited);
      setDisappear(item.accredited !== null ? item.accredited : false);
      setValue('url_facebook', item.url_facebook);
      setValue('url_linkedin', item.url_linkedin);
      setValue('url_instagram', item.url_instagram);
      setValue('video_about', item.video_about);
      setValue('workshift', item.workshift);
      setValue('title_grants', item.title_grants);
      // setValue('name_brochure', item.name_brochure);
      setValue('about_egresado', item.about_egresado);
      setValue('requirements', item.requirements);
      setValue('name_egresado', item.name_egresado);
      // if (item.studyplans) {
      //   brochureURL = `${process.env.REACT_APP_BACKEND_URL}/storage/studyplans/${item.studyplans}`;
      //   savePDF(brochureURL);
      // }
      const selectedOption = cities.find(find => find._id === item.city?._id);
      if (selectedOption) {
        setOptionCities({
          value: selectedOption._id,
          label: selectedOption.name
        });
      }
      const selectedLevel = levels.find(find => find._id === item.level?._id);
      if (selectedLevel) {
        setOptionLevel({
          value: selectedLevel._id,
          label: selectedLevel.name
        });
      }
      const selectedModality = modalitys.find(
        find => find._id === item.modality?._id
      );
      if (selectedModality) {
        setOptionModality({
          value: selectedModality._id,
          label: selectedModality.name
        });
      }
      const selectedBasicCores = basicCores.find(
        find => find._id === item.basic_core?._id
      );
      if (selectedBasicCores) {
        setOptionBasicCores({
          value: selectedBasicCores._id,
          label: selectedBasicCores.name
        });
      }
      const selectedPeriods = periods.find(
        find => find._id === item.periods?._id
      );
      if (selectedPeriods) {
        setOptionPeriods({
          value: selectedPeriods._id,
          label: selectedPeriods.name
        });
      }
      const selectedUniversity = universities.find(
        find => find._id === item.university?._id
      );
      if (selectedUniversity) {
        setOptionUniversity({
          value: selectedUniversity._id,
          label: selectedUniversity.name
        });
      }
      egresadoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.image_egresado}`;
      chargeURL = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.image_cargos}`;
      spreadUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.image_diferenciales}`;
      scholarshipURL = `${process.env.REACT_APP_BACKEND_URL}/storage/backgrounds/${item.image_auxilios}`;
    } else {
      if (Object.keys(university).length !== 0) {
        setOptionUniversity(university);
      }
      egresadoUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`;
      chargeURL = `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`;
      spreadUrl = `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`;
      scholarshipURL = `${process.env.REACT_APP_BACKEND_URL}/storage/estandar.png`;
    }
    saveImageEgresado(egresadoUrl);
    saveImageCharge(chargeURL);
    saveImageSpread(spreadUrl);
    saveImageScholarship(scholarshipURL);
  }, [watch('id')]);

  const saveImageEgresado = async logoUrl => {
    setEgresadoUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'estandar.jpg', { type: 'image/jpeg' });
    setEgresado(file);
  };
  const verifyImageEgresado = async logo => {
    setAvailabledEgresado(null);
    const file = logo;
    if (file) {
      setEgresadoUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.size < 21000000) {
            setEgresado(file);
          } else {
            setAvailabledEgresado('El tamaño del fichero excede los 1mb!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledEgresado('Este fichero no es una imagen!');
      }
    }
  };
  const saveImageCharge = async logoUrl => {
    setChargeUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'estandar2.jpg', { type: 'image/jpeg' });
    setCharge(file);
  };
  const verifyImageCharge = async logo => {
    setAvailabledCharge(null);
    const file = logo;
    if (file) {
      setChargeUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.size < 21000000) {
            setCharge(file);
          } else {
            setAvailabledCharge('El tamaño del fichero excede los 1mb!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledCharge('Este fichero no es una imagen!');
      }
    }
  };
  const saveImageSpread = async logoUrl => {
    setSpreadUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'estandar3.jpg', { type: 'image/jpeg' });
    setSpread(file);
  };
  const verifyImageSpread = async logo => {
    setAvailabledSpread(null);
    const file = logo;
    if (file) {
      setSpreadUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.size < 21000000) {
            setSpread(file);
          } else {
            setAvailabledSpread('El tamaño del fichero excede los 1mb!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledSpread('Este fichero no es una imagen!');
      }
    }
  };
  const saveImageScholarship = async logoUrl => {
    setScholarshipUrl(logoUrl);
    const response = await fetch(logoUrl);
    const blob = await response.blob();
    const file = new File([blob], 'estandar4.jpg', { type: 'image/jpeg' });
    setScholarship(file);
  };
  const verifyImageScholarship = async logo => {
    setAvailabledScholarship(null);
    const file = logo;
    if (file) {
      setScholarshipUrl(URL.createObjectURL(file));
      if (file.type && file.type.startsWith('image/')) {
        try {
          if (file.size < 21000000) {
            setScholarship(file);
          } else {
            setAvailabledScholarship('El tamaño del fichero excede los 1mb!');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledSpread('Este fichero no es una imagen!');
      }
    }
  };

  useEffect(() => {}, [watch('studyplans')]);
  const handleDisappearChange = e => {
    setDisappear(e.target.checked);
  };
  useEffect(() => {
    if (!item?._id || item?.code !== watch('code')) {
      setAvailabledCode(true);
      setAvailabledCodeMsg('');
      items.forEach(dat => {
        if (dat.code === watch('code') && watch('code') !== 'N/A') {
          setAvailabledCode(false);
          setAvailabledCodeMsg('este código está en uso actualmente!');
        }
      });
    }
  }, [watch('code')]);

  // const savePDF = async pdfUrl => {
  //   const response = await fetch(pdfUrl);
  //   const blob = await response.blob();
  //   const file = new File([blob], 'document.pdf', {
  //     type: 'application/pdf'
  //   });
  //   setPDF(file);
  //   setValue('pdf', [file]);
  // };
  const verifyPDF = async logo => {
    setAvailabledPDF(null);
    const file = logo;
    if (file) {
      if (file.type && file.type.startsWith('application/pdf')) {
        try {
          setPDF(file);
        } catch (error) {
          console.error(error);
        }
      } else {
        setAvailabledPDF('Este fichero no es un pdf!');
      }
    }
  };
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="px-1">
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
        <Tab eventKey="home" title=" Descripción general">
          <Row>
            <Col>
              <div className="row w-100">
                <div className="col-md-11">
                  <div id="name-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">Nombre (*)</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Nombre del Programa"
                        isInvalid={!!errors.name}
                        {...register('name', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name && errors.name.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div id="code-row" className="mb-1">
                    <Form.Group>
                      <Form.Label className="mb-1">
                        Código Snies (*){' '}
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        id="code"
                        name="code"
                        placeholder="Código Snies"
                        isInvalid={!!errors.code}
                        {...register('code', {
                          required: {
                            value: true,
                            message: 'este campo es requerido!'
                          },
                          minLength: {
                            limit: 1,
                            value: 1,
                            message: 'Mínimo 1 carácter!'
                          }
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.code && errors.code.message}
                      </Form.Control.Feedback>
                      {availabledCode ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledCodeMsg}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="cities-row">
                    <Form.Group className="mb-1">
                      <Form.Label className="mb-1 mt-2">Ciudad (*)</Form.Label>
                      <ReactSelect
                        closeMenuOnSelect={true}
                        options={cities.map(find => ({
                          value: find._id,
                          label: find.name
                        }))}
                        placeholder="Seleccionar...."
                        classNamePrefix="react-select"
                        value={optionCities}
                        id="cities"
                        onChange={optionCity => setOptionCities(optionCity)}
                      />
                      {availabledCities ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsgCities}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  <div id="level-row">
                    <Form.Group className="mb-1">
                      <Form.Label className="mb-1 mt-2">
                        Nivel Académico (*){' '}
                      </Form.Label>
                      <ReactSelect
                        closeMenuOnSelect={true}
                        options={levels.map(find => ({
                          value: find._id,
                          label: find.name
                        }))}
                        placeholder="Seleccionar...."
                        classNamePrefix="react-select"
                        value={optionLevel}
                        id="Levels"
                        onChange={option => setOptionLevel(option)}
                      />
                      {availabledLevels ? null : (
                        <h6 className="text-danger fs--2 mt-2 mb-0">
                          {availabledMsgLevels}
                        </h6>
                      )}
                    </Form.Group>
                  </div>
                  {Object.keys(university).length === 0 && (
                    <div id="university-row">
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 mt-2">
                          Universidad (*)
                        </Form.Label>
                        <ReactSelect
                          closeMenuOnSelect={true}
                          options={universities.map(find => ({
                            value: find._id,
                            label: find.name
                          }))}
                          placeholder="Seleccionar...."
                          classNamePrefix="react-select"
                          value={optionUniversity}
                          id="university"
                          onChange={option => setOptionUniversity(option)}
                        />
                        {availabledUniversity ? null : (
                          <h6 className="text-danger fs--2 mt-2 mb-0">
                            {availabledMsgUniversity}
                          </h6>
                        )}
                      </Form.Group>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="row w-100">
                <div id="basic_core-row" className="mb-3">
                  <Form.Group>
                    <Form.Label className="mb-1 mt-2">
                      Nucleo Básico Conocimiento (*){' '}
                    </Form.Label>
                    <ReactSelect
                      closeMenuOnSelect={true}
                      options={basicCores.map(find => ({
                        value: find._id,
                        label: find.name
                      }))}
                      placeholder="Seleccionar...."
                      classNamePrefix="react-select"
                      value={optionBasicCores}
                      id="basic_core"
                      onChange={option => setOptionBasicCores(option)}
                    />
                    {availabledBasicCores ? null : (
                      <h6 className="text-danger fs--2 mt-2 mb-0">
                        {availabledMsgBasicCores}
                      </h6>
                    )}
                  </Form.Group>
                </div>
                <div id="periods-row" className="mb-3">
                  <Form.Group>
                    <Form.Label className="mb-1 mt-2">
                      Períodos de Duración (*)
                    </Form.Label>
                    <ReactSelect
                      closeMenuOnSelect={true}
                      options={periods.map(find => ({
                        value: find._id,
                        label: find.name
                      }))}
                      placeholder="Seleccionar...."
                      classNamePrefix="react-select"
                      value={optionPeriods}
                      id="periods"
                      onChange={option => setOptionPeriods(option)}
                    />
                    {availabledPeriods ? null : (
                      <h6 className="text-danger fs--2 mt-2 mb-0">
                        {availabledMsgPeriods}
                      </h6>
                    )}
                  </Form.Group>
                </div>
                <div id="modality-row" className="mb-3">
                  <Form.Group className="mb-1">
                    <Form.Label className="mb-1 mt-2">Modalidad (*)</Form.Label>
                    <ReactSelect
                      closeMenuOnSelect={true}
                      options={modalitys.map(find => ({
                        value: find._id,
                        label: find.name
                      }))}
                      placeholder="Seleccionar...."
                      classNamePrefix="react-select"
                      value={optionModality}
                      id="modality"
                      onChange={option => setOptionModality(option)}
                    />
                    {availabledModality ? null : (
                      <h6 className="text-danger fs--2 mt-2 mb-0">
                        {availabledMsgModality}
                      </h6>
                    )}
                  </Form.Group>
                </div>
                <div id="duration-row" className="mb-3">
                  <Form.Group>
                    <Form.Label className="mb-1">Duración (*)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      id="duration"
                      name="duration"
                      placeholder="Duración"
                      isInvalid={!!errors.duration}
                      {...register('duration', {
                        required: {
                          value: true,
                          message: 'este campo es requerido!'
                        },
                        minLength: {
                          limit: 1,
                          value: 1,
                          message: 'Mínimo 1 carácter!'
                        }
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.duration && errors.duration.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Col>
            <Col>
              <div className="row w-100">
                <div id="price-row" className="mb-1">
                  <Form.Group>
                    <Form.Label className="mb-1">Precio (*)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      id="price"
                      name="price"
                      placeholder="Precio"
                      isInvalid={!!errors.price}
                      {...register('price', {
                        required: {
                          value: true,
                          message: 'este campo es requerido!'
                        }
                      })}
                    />
                    <div
                      className={`${
                        localStorage.getItem('isDark') === 'true'
                          ? 'text-400'
                          : 'text-700'
                      }`}
                      style={{
                        marginTop: '5px',
                        fontSize: '12px'
                      }}
                    >
                      Solo valor numérico con puntos decimales
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.price && errors.price.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div id="credits-row" className="mb-1">
                  <Form.Group>
                    <Form.Label className="mb-1">Créditos (*)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      id="credits"
                      name="credits"
                      placeholder="Créditos"
                      isInvalid={!!errors.credits}
                      {...register('credits', {
                        required: {
                          value: true,
                          message: 'este campo es requerido!'
                        }
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.credits && errors.credits.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div id="salary-row" className="mb-1">
                  <Form.Group>
                    <Form.Label className="mb-1">Salario Medio (*)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      id="salary"
                      name="salary"
                      placeholder="Salario Medio "
                      isInvalid={!!errors.salary}
                      {...register('salary', {
                        required: {
                          value: true,
                          message: 'este campo es requerido!'
                        }
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.salary && errors.salary.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div id="workshift-row" className="mb-1">
                  <Form.Group>
                    <Form.Label className="mb-1">Jornada (*)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      id="workshift"
                      name="workshift"
                      placeholder="Diurno a nocturno"
                      isInvalid={!!errors.workshift}
                      {...register('workshift', {
                        required: {
                          value: true,
                          message: 'este campo es requerido!'
                        }
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.workshift && errors.workshift.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div id="studyplans-row" className="mb-3">
                  <Form.Group>
                    <Form.Label className="mb-1">Plan de estudio</Form.Label>
                    <Form.Control
                      size="sm"
                      type="file"
                      id="studyplans"
                      name="studyplans"
                      onChange={e => {
                        verifyPDF(e.target.files[0]);
                      }}
                    />
                    <div
                      className="ms-2 text-truncate"
                      style={{ maxWidth: '300px' }}
                    >
                      {pdf && <Form.Text>{pdf.name}</Form.Text>}
                      {item.studyplans && (
                        <Form.Text>{item.studyplans}</Form.Text>
                      )}
                    </div>

                    {availabledPDF !== null && (
                      <h6 className="text-danger fs--2 mt-2 mb-0">
                        {availabledPDF}
                      </h6>
                    )}
                    <div
                      className={`${
                        localStorage.getItem('isDark') === 'true'
                          ? 'text-400'
                          : 'text-700'
                      }`}
                      style={{
                        marginTop: '5px',
                        fontSize: '12px'
                      }}
                    >
                      Se permiten solo archivos (.pdf) con máximo de peso de 1mb
                    </div>
                  </Form.Group>
                </div>
                <Form.Group className="mb-3">
                  <Form.Check
                    required
                    id="accredited"
                    label="Programa Acreditado"
                    checked={disappear}
                    onChange={handleDisappearChange}
                  />
                </Form.Group>
              </div>
            </Col>
            <div id="about-row" className="mb-3">
              <Form.Group>
                <Form.Label className="mb-1">Sobre el Programa (*)</Form.Label>
                <div style={{ border: errors.about ? '2px solid red' : '' }}>
                  <Editor
                    id="about"
                    name="about"
                    value={watch('about')}
                    onEditorChange={newValue => {
                      setValue('about', newValue);
                      if (errors.about) clearErrors('about');
                    }}
                    apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                    init={{
                      height: '200px',
                      menubar: false,
                      content_style: `
                        .mce-content-body {
                          color: ${
                            isDark ? 'white' : getColor('emphasis-color')
                          };                          
                          background-color: ${getColor(
                            'tinymce-bg'
                          )};                          
                        }`,
                      statusbar: false,
                      plugins: 'lists directionality',
                      toolbar:
                        'styleselect | bold italic link bullist numlist image table media undo redo',
                      directionality: isRTL ? 'rtl' : 'ltr',
                      theme_advanced_toolbar_align: 'center'
                    }}
                    {...register('about', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      }
                    })}
                  />
                </div>
                {errors.about && (
                  <div style={{ color: 'red' }}>{errors.about.message}</div>
                )}
                {/* <Form.Control
                  size="sm"
                  as="textarea"
                  id="about"
                  name="about"
                  rows={3}
                  placeholder="Descripción"
                  isInvalid={!!errors.about}
                  {...register('about', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    },
                    minLength: {
                      limit: 2,
                      value: 2,
                      message: 'Mínimo 1 carácter!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.about && errors.about.message}
                </Form.Control.Feedback> */}
              </Form.Group>
            </div>
          </Row>
        </Tab>
        <Tab eventKey="url" title="Información específica programa">
          <Row>
            <Col>
              <div id="final_registration-row" className="mb-1">
                <Form.Group>
                  <Form.Label className="mb-1">
                    Fecha final Inscripciones (*)
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    id="final_registration"
                    name="final_registration"
                    placeholder="Fecha final Inscripciones"
                    isInvalid={!!errors.final_registration}
                    max={watch('start_classes')}
                    {...register('final_registration', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      },
                      minLength: {
                        limit: 1,
                        value: 1,
                        message: 'Mínimo 1 carácter!'
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.final_registration &&
                      errors.final_registration.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div id="start_classes-row" className="mb-1">
                <Form.Group>
                  <Form.Label className="mb-1">Inicio de clases (*)</Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    min={watch('final_registration')}
                    id="start_classes"
                    name="start_classes"
                    placeholder="Inicio de clases"
                    isInvalid={!!errors.start_classes}
                    {...register('start_classes', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      },
                      minLength: {
                        limit: 1,
                        value: 1,
                        message: 'Mínimo 1 carácter!'
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.start_classes && errors.start_classes.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>

            <div id="title_grants-row" className="mb-1">
              <Form.Group>
                <Form.Label className="mb-1">Titulo que otorga (*)</Form.Label>
                <div
                  style={{ border: errors.title_grants ? '2px solid red' : '' }}
                >
                  <Editor
                    id="title_grants"
                    name="title_grants"
                    value={watch('title_grants')}
                    onEditorChange={newValue => {
                      setValue('title_grants', newValue);
                      if (errors.title_grants) clearErrors('title_grants');
                    }}
                    apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                    init={{
                      height: '150px',
                      menubar: false,
                      content_style: `
                        .mce-content-body {
                          color: ${
                            isDark ? 'white' : getColor('emphasis-color')
                          };                          
                          background-color: ${getColor(
                            'tinymce-bg'
                          )};                          
                        }`,
                      statusbar: false,
                      plugins: 'lists directionality',
                      toolbar:
                        'styleselect | bold italic link bullist numlist image table media undo redo',
                      directionality: isRTL ? 'rtl' : 'ltr',
                      theme_advanced_toolbar_align: 'center'
                    }}
                    {...register('title_grants', {
                      required: {
                        value: true,
                        message: 'este campo es requerido!'
                      }
                    })}
                  />
                </div>
                {errors.title_grants && (
                  <div style={{ color: 'red' }}>
                    {errors.title_grants.message}
                  </div>
                )}
                {/* <Form.Control
                  size="sm"
                  style={{ height: '40px' }}
                  as="textarea"
                  id="title_grants"
                  name="title_grants"
                  placeholder="Titulo que otorga"
                  isInvalid={!!errors.title_grants}
                  {...register('title_grants', {
                    required: {
                      value: true,
                      message: 'este campo es requerido!'
                    },
                    minLength: {
                      limit: 2,
                      value: 2,
                      message: 'Mínimo 1 carácter!'
                    }
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title_grants && errors.title_grants.message}
                </Form.Control.Feedback> */}
              </Form.Group>
            </div>
            <div id="requirements-row" className="mb-3">
              <Form.Group>
                <Form.Label className="mb-1">Requisitos Especiales</Form.Label>
                <div
                  style={{ border: errors.requirements ? '2px solid red' : '' }}
                >
                  <Editor
                    id="requirements"
                    name="requirements"
                    value={watch('requirements')}
                    onEditorChange={newValue => {
                      setValue('requirements', newValue);
                    }}
                    apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                    init={{
                      height: '150px',
                      menubar: false,
                      content_style: `
                        .mce-content-body {
                          color: ${
                            isDark ? 'white' : getColor('emphasis-color')
                          };                          
                          background-color: ${getColor(
                            'tinymce-bg'
                          )};                          
                        }`,
                      statusbar: false,
                      plugins: 'lists directionality',
                      toolbar:
                        'styleselect | bold italic link bullist numlist image table media undo redo',
                      directionality: isRTL ? 'rtl' : 'ltr',
                      theme_advanced_toolbar_align: 'center'
                    }}
                  />
                </div>
                {/* <Form.Control
                  size="sm"
                  as="textarea"
                  id="requirements"
                  name="requirements"
                  rows={3}
                  placeholder="Lorem..."
                /> */}
              </Form.Group>
            </div>
          </Row>
        </Tab>
        <Tab eventKey="perfil" title="Perfil de egresado">
          <Row>
            <Col>
              <div id="name_egresado-row" className="mb-1">
                <Form.Group>
                  <Form.Label className="mb-1">Título</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    id="name_egresado"
                    name="name_egresado"
                    placeholder="¿Que aprenderás?"
                    isInvalid={!!errors.name_egresado}
                    {...register('name_egresado', {
                      minLength: {
                        limit: 1,
                        value: 1,
                        message: 'Mínimo 1 carácter!'
                      }
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name_egresado && errors.name_egresado.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <Form.Group>
                {egresadoUrl && availabledEgresado === null && (
                  <div
                    style={{
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto'
                    }}
                  >
                    <img
                      src={egresadoUrl}
                      alt="Preview"
                      style={{
                        objectFit: 'cover',
                        maxWidth: '100%',
                        maxHeight: '100%'
                      }}
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: '10px',
                  color: '#000'
                }}
              >
                {' '}
                Resolución Máxima: 840x560
              </Form.Label>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  color: '#000'
                }}
              >
                {' '}
                Tamaño máximo: 1mb
              </Form.Label>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  color: '#000'
                }}
              >
                {' '}
                Formato permitido: .png .jpg .jpeg
              </Form.Label>
              <div id="image_egresado-row" className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-1">Imagen de Egresado</Form.Label>
                  <Form.Control
                    size="sm"
                    type="file"
                    id="image_egresado"
                    name="image_egresado"
                    onChange={e => {
                      verifyImageEgresado(e.target.files[0]);
                    }}
                  />
                  {availabledEgresado !== null && (
                    <h6 className="text-danger fs--2 mt-2 mb-0">
                      {availabledEgresado}
                    </h6>
                  )}
                </Form.Group>
              </div>
            </Col>
            <Col>
              {' '}
              <div id="about_egresado-row" className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-1">Perfil Egresado</Form.Label>
                  <div
                    style={{
                      border: errors.about_egresado ? '2px solid red' : ''
                    }}
                  >
                    <Editor
                      id="about_egresado"
                      name="about_egresado"
                      value={watch('about_egresado')}
                      onEditorChange={newValue => {
                        setValue('about_egresado', newValue);
                      }}
                      apiKey={process.env.REACT_APP_TINYMCE_APIKEY}
                      init={{
                        height: '310px',
                        menubar: false,
                        content_style: `
                        .mce-content-body {
                          color: ${
                            isDark ? 'white' : getColor('emphasis-color')
                          };                          
                          background-color: ${getColor(
                            'tinymce-bg'
                          )};                          
                        }`,
                        statusbar: false,
                        plugins: 'lists directionality',
                        toolbar:
                          'styleselect | bold italic link bullist numlist image table media undo redo',
                        resize: 'both',
                        directionality: isRTL ? 'rtl' : 'ltr',
                        theme_advanced_toolbar_align: 'center'
                      }}
                    />
                  </div>
                  {/* <Form.Control
                    size="sm"
                    style={{ height: '40px' }}
                    as="textarea"
                    id="about_egresado"
                    name="about_egresado"
                    placeholder="Perfil Egresado"
                  /> */}
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="image" title="Imágenes de apoyo">
          <Row>
            <Col>
              <Form.Group>
                {chargeUrl && availabledCharge === null && (
                  <div
                    style={{
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto'
                    }}
                  >
                    <img
                      src={chargeUrl}
                      alt="Preview"
                      style={{
                        objectFit: 'cover',
                        maxWidth: '100%',
                        maxHeight: '100%'
                      }}
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: '10px',
                  color: '#000'
                }}
              >
                {' '}
                Resolución Máxima: 840x560
              </Form.Label>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  color: '#000'
                }}
              >
                {' '}
                Tamaño máximo: 1mb
              </Form.Label>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  color: '#000'
                }}
              >
                {' '}
                Formato permitido: .png .jpg .jpeg
              </Form.Label>
              <div id="image_cargos-row" className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-1">Imagen de Cargos</Form.Label>
                  <Form.Control
                    size="sm"
                    type="file"
                    id="image_cargos"
                    name="image_cargos"
                    onChange={e => {
                      verifyImageCharge(e.target.files[0]);
                    }}
                  />
                  {availabledCharge !== null && (
                    <h6 className="text-danger fs--2 mt-2 mb-0">
                      {availabledCharge}
                    </h6>
                  )}
                </Form.Group>
              </div>
            </Col>
            <Col>
              <Form.Group>
                {spreadUrl && availabledSpread === null && (
                  <div
                    style={{
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto'
                    }}
                  >
                    <img
                      src={spreadUrl}
                      alt="Preview"
                      style={{
                        objectFit: 'cover',
                        maxWidth: '100%',
                        maxHeight: '100%'
                      }}
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: '10px',
                  color: '#000'
                }}
              >
                {' '}
                Resolución Máxima: 840x560
              </Form.Label>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  color: '#000'
                }}
              >
                {' '}
                Tamaño máximo: 1mb
              </Form.Label>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  color: '#000'
                }}
              >
                {' '}
                Formato permitido: .png .jpg .jpeg
              </Form.Label>
              <div id="image_diferenciales-row" className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-1">
                    Imagen de Diferencial
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="file"
                    id="image_diferenciales"
                    name="image_diferenciales"
                    onChange={e => {
                      verifyImageSpread(e.target.files[0]);
                    }}
                  />
                  {availabledSpread !== null && (
                    <h6 className="text-danger fs--2 mt-2 mb-0">
                      {availabledSpread}
                    </h6>
                  )}
                </Form.Group>
              </div>
            </Col>
            <Col>
              <Form.Group>
                {scholarshipUrl && availabledScholarship === null && (
                  <div
                    style={{
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: 'auto'
                    }}
                  >
                    <img
                      src={scholarshipUrl}
                      alt="Preview"
                      style={{
                        objectFit: 'cover',
                        maxWidth: '100%',
                        maxHeight: '100%'
                      }}
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  marginTop: '10px',
                  color: '#000'
                }}
              >
                {' '}
                Resolución Máxima: 840x560
              </Form.Label>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  color: '#000'
                }}
              >
                {' '}
                Tamaño máximo: 1mb
              </Form.Label>
              <Form.Label
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                  color: '#000'
                }}
              >
                {' '}
                Formato permitido: .png .jpg .jpeg
              </Form.Label>
              <div id="image_auxilios-row" className="mb-3">
                <Form.Group>
                  <Form.Label className="mb-1">
                    Imagen de Beca o Auxilios
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    type="file"
                    id="image_auxilios"
                    name="image_auxilios"
                    onChange={e => {
                      verifyImageScholarship(e.target.files[0]);
                    }}
                  />
                  {availabledScholarship !== null && (
                    <h6 className="text-danger fs--2 mt-2 mb-0">
                      {availabledScholarship}
                    </h6>
                  )}
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <Button
        type="submit"
        size="sm"
        variant="primary"
        disabled={
          loadBotton ||
          optionCities === null ||
          optionLevel === null ||
          optionBasicCores === null ||
          optionModality === null ||
          optionPeriods === null ||
          optionUniversity === null ||
          !watch('title_grants') ||
          !watch('start_classes') ||
          !watch('final_registration') ||
          !watch('name') ||
          !watch('code') ||
          !watch('about') ||
          !watch('duration') ||
          !watch('price') ||
          !watch('credits') ||
          !watch('salary') ||
          !watch('workshift')
        }
        className="rounded-pill me-1 mb-3 w-100 d-inline-flex flex-center gap-1 p-1"
      >
        {loadBotton && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {loadBotton
          ? 'Cargando…'
          : watch('id')
          ? 'Actualizar Datos'
          : 'Guardar Datos'}
      </Button>
    </Form>
  );
};

University.propTypes = {
  submit: PropTypes.func.isRequired,
  item: PropTypes.object,
  items: PropTypes.array,
  modal: PropTypes.bool
};

export default University;
