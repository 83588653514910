import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { useDefault, useAuth, useUniversity, http } from 'hooks';
import headers from 'config/header';
import Form_ from 'components/forms/UniversityForm';

const UniversityProfileContext = createContext();

const UniversityProfileProvider = ({ children }) => {
  const [item, setItem] = useState({});
  const [items, setItems] = useState([]);
  const [univConv, setUnivConv] = useState([]);
  const [name, setName] = useState(null);
  const modalSize = 'xl';
  const { list, remove, update, add } = useDefault();
  const { setItem: setUniversity } = useUniversity();

  const { isAuthenticated } = useAuth();

  //* GET ALL REGISTER
  useEffect(() => {
    list('universities/list').then(data => {
      if (data) {
        setItems(data);
      }
    });
    setUniversity(null);
  }, [isAuthenticated]);

  //* GET ALL UNIV. CONVENIUN
  useEffect(() => {
    if (isAuthenticated) {
      getUnivConv();
    }
  }, [isAuthenticated]);

  const getUnivConv = async () => {
    try {
      const { data } = await http('universities/agreements', headers());
      setUnivConv(data);
    } catch (e) {
      console.log(e);
    }
  };
  const submit = object_ => {
    if (object_.get('_id')) {
      object_._id = object_.get('_id');
      update('universities/update', object_).then(res => {
        res &&
          (setItems(
            items.map(stateObj => (stateObj._id === res._id ? res : stateObj))
          ),
          setItem({}));
      });
    } else
      add('universities/add', object_).then(
        res => res && setItems([...items, res])
      );
  };
  //const submit = async () => {};

  const removeOne = async () => {
    remove(`universities/delete/${item._id}`).then(() => {
      setItems(items.filter(stateObj => stateObj._id !== item._id));
      setItem({});
    });
  };

  return (
    <UniversityProfileContext.Provider
      value={{
        removeOne,
        item,
        items,
        setItem,
        setItems,
        name,
        setName,
        modalSize,
        setUniversity,
        submit,
        univConv,
        caption: 'Universidad',
        captions: 'Universidades',
        Form_: Form_,
        confirm: true
      }}
    >
      {children}
    </UniversityProfileContext.Provider>
  );
};

UniversityProfileProvider.propTypes = {
  children: PropTypes.node
};

export { UniversityProfileProvider };
export default UniversityProfileContext;
